<script lang="ts" setup>
const partners = [
  {
    name: 'Zoom Cargo',
    href: 'https://www.zoom-cargo.com/',
    imageName: 'partner-zc.svg',
  },
  {
    name: 'Auction24',
    href: 'https://auction24.cz/',
    imageName: 'partner-auction.svg',
  },
  {
    name: 'Apus Finance',
    href: 'https://apus.finance/',
    imageName: 'partner-apus.svg',
  },
  {
    name: 'KM Fire',
    href: 'https://kmfire.cz/',
    imageName: 'partner-km.svg',
  },
  {
    name: 'Home Credit',
    href: 'https://www.homecredit.net/',
    imageName: 'partner-hc.svg',
  },
  {
    name: 'SKV Praha',
    href: 'https://www.skvpraha.org/skv-praha/',
    imageName: 'partner-skv.svg',
  },
  {
    name: 'I\'m Vegan',
    href: 'https://play.google.com/store/apps/details?id=com.kleinpetr.vegan',
    imageName: 'partner-vegan.svg',
  },
  {
    name: 'Bess Plus',
    href: 'https://bessplus.cz/',
    imageName: 'partner-bess.svg',
  },
  {
    name: 'PWN',
    href: 'https://pwn.xyz/',
    imageName: 'partner-PWN.svg',
  },
  {
    name: 'BatteryCheck',
    href: 'https://www.batterycheck.com/',
    imageName: 'partner-batterycheck.svg',
  },
  {
    name: 'Web3Privacy',
    href: 'https://web3privacy.info/',
    imageName: 'partner-web3privacy.svg',
  },
  {
    name: 'Donat.Network',
    href: 'https://donat.network/',
    imageName: 'partner-donat_network.svg',
  },
  {
    name: 'EIG',
    href: 'https://www.eig.ag/',
    imageName: 'partner-eig.svg',
  },
  {
    name: 'CryptoByte',
    href: 'https://cryptobyte.cz/',
    imageName: 'partner-cryptobyte.svg',
  },
  {
    name: 'Bitbeli',
    href: 'https://bitbeli.cz/',
    imageName: 'partner-bitbeli.svg',
  },
  {
    name: 'Miss Czech Republic',
    href: '/',
    imageName: 'partner-missczechrep.svg',
  },
  {
    name: 'Exellent Montage',
    href: 'https://exellent-montage.com/',
    imageName: 'partner-exellent_montage.svg',
  },
  {
    name: 'We The Hand Players',
    href: 'https://wthp.studio/',
    imageName: 'partner-whtp.svg',
  },
]
</script>

<template>
  <section id="partners" class="py-14 pb-[160p] lg:py-[100px]">
    <div class="container">
      <div class="heading text-center">
        <h6>{{ $t('partners.title') }}</h6>
        <h4>{{ $t('partners.subtitle') }}</h4>
      </div>
    </div>
    <div class="mx-auto w-4/5 py-10 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 md:gap-4 xl:gap-6 place-items-center">
      <div v-for="partner in partners" :key="partner.href">
        <a
          target="_blank" :href="partner.href" :aria-label="`Check out more about ${partner.name}`"
          class="flex justify-center grayscale-[75%] hover:grayscale-0 h-[100px] w-[120px]"
        >
          <img :src="`/assets/images/partners/${partner.imageName}`" :alt="partner.name">
        </a>
      </div>
    </div>
  </section>
</template>
