<script setup lang="ts">
import Info from '@/components/Info.vue'
import Offers from '@/components/Offers.vue'
import Services from '@/components/Services.vue'
import Team from '@/components/Team.vue'
import Partners from '@/components/Partners.vue'
import Contact from '@/components/Contact.vue'

const { t } = useI18n()

useHead({
  title: 'Develit.io',
  titleTemplate: 'Develit.io',
  meta: [
    { charset: 'utf-8' },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no',
    },
    { hid: 'description', name: 'description', content: t('app.description') },
    { name: 'format-detection', content: 'telephone=no' },
    {
      name: 'description',
      content: t('app.description'),
    },
    {
      property: 'og:image',
      content: 'https://develit.io/develit-icon.png',
    },
  ],
  script: [
    {
      'src': 'https://plausible.io/js/script.js',
      'defer': true,
      'data-domain': 'develit.io',
    },
  ],
  link: [
    { rel: 'icon', type: 'image/svg', href: '/favicon.svg' },
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;700;800&display=swap',
    },
  ],
})
</script>

<template>
  <div class="overflow-x-hidden">
    <Info />

    <Offers />

    <Services />

    <Team />

    <Partners />

    <Contact />
  </div>
</template>
