<script lang="ts" setup>
const team = [
  {
    name: 'Klein Petr',
    role: 'Solution Architect',
    secondaryRole: 'CEO',
    imageName: 'avatar-petr.png',
  },
  {
    name: 'Robert',
    role: 'Co-Founder',
    imageName: 'avatar-robert.png',
  },
  {
    name: 'Radek',
    role: 'Advisor',
    imageName: 'avatar-radek.png',
  },
  {
    name: 'Daniel',
    role: 'Frontend Engineer',
    secondaryRole: 'Lead',
    imageName: 'avatar-daniel.png',
  },
  {
    name: 'PabloCZ',
    role: 'Sales Director',
    imageName: 'avatar-pablo.png',
  },
  {
    name: 'Tonda',
    role: 'Frontend Engineer',
    imageName: 'avatar-tonda.png',
  },
  {
    name: 'Petr',
    role: 'Frontend Engineer',
    imageName: 'avatar-petrb.png',
  },
  {
    name: 'Dominik',
    role: 'Frontend Engineer',
    imageName: 'avatar-dominik.png',
  },
  {
    name: 'Erik',
    role: 'Smart Contract',
    imageName: 'avatar-erik.png',
  },
  {
    name: 'Petra',
    role: 'Backoffice',
    imageName: 'avatar-petra.png',
  },
  {
    name: 'Viktor',
    role: 'Advisor',
    imageName: 'avatar-viktor.png',
  },
  {
    name: 'Filip',
    role: 'Full Stack Engineer',
    imageName: 'avatar-filip.png',
  },
  {
    name: 'Martin',
    role: 'Frontend Engineer',
    imageName: 'avatar-martin.png',
  },
  {
    name: 'Daniel',
    role: 'Frontend Engineer',
    imageName: 'avatar-daniell.png',
  },
  {
    name: 'Coinmandeer',
    role: 'UI/UX Designer',
    imageName: 'avatar-coinmandeer.png',
    link: {
      text: 'See portfolio',
      href: 'https://coinmandeer.com',
    },
  },
]
</script>

<template>
  <section
    id="team"
    class="bg-[url(/assets/images/team-bg.png)] bg-cover bg-center bg-no-repeat py-14 dark:bg-black/50 lg:py-[100px]"
  >
    <div class="container">
      <div class="heading text-center">
        <h6>{{ $t('team.title') }}</h6>
        <h4>{{ $t('team.subtitle') }}</h4>
      </div>
      <div class="grid gap-8 grid-cols-2 sm:grid-cols-3 lg:grid-cols-5">
        <div v-for="person in team" :key="person.name" class="group text-center">
          <div
            class="relative h-[280px] rounded-3xl transition-all duration-500 group-hover:shadow-[0_0_25px_#979797]"
          >
            <img
              :src="`/assets/images/avatars/${person.imageName}`" :alt="person.name"
              class="h-full rounded-3xl object-cover object-top grayscale-[75%] hover:grayscale-0" :class="{ 'grayscale-[90%]': person.name === 'Coinmandeer' }"
            >
          </div>
          <h4
            class="pt-5 pb-2 text-xl font-extrabold text-black transition duration-500 group-hover:text-secondary dark:text-white"
          >
            {{ person.name }}
          </h4>
          <h6 class="text-sm font-bold">
            {{ person.role }}
            <div v-if="person.secondaryRole">
              {{ person.secondaryRole }}
            </div>
            <div v-if="person.link" class="text-secondary">
              <a :href="person.link.href" target="_blank" rel="noopener noreferrer">{{ person.link.text }}</a>
            </div>
          </h6>
        </div>
      </div>
    </div>
  </section>
</template>
